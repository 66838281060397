import Layout from '@layouts/Layout'
import { useTranslation } from '@src/i18n'
import { makeStyles } from '@material-ui/core/styles'
import links from '@api/links'

import Box from '@base/Box'
import Link from '@base/Link'
import Typography from '@base/Typography'

import Line from '@components/flow/Line'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 112px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '2rem',
    marginBottom: '40px',
    [breakpoints.up('md')]: {
      fontSize: '2.3rem',
    },
  },
  subtitle: {
    fontSize: '14px',
    color: '#626261',
    fontWeight: 600,
    marginTop: '16px',
  },
}))

export default function FourOhFour() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Layout footer={false} headerProps={{ style: { border: 'none' } }}>
      <Box maxWidth='100%' overflow='hidden' width='100%'>
        <Box className={classes.root}>
          <Box maxWidth={590} mx={2} my={10}>
            <Line showMobile />
            <Typography component='h1' variant='h3' className={classes.title}>
              {t('base.404.title')}
            </Typography>
            <Box maxWidth={390}>
              <Typography className={classes.subtitle}>{t('base.404.text1')}</Typography>
              <Typography className={classes.subtitle}>
                {t('base.404.text2')}
                <Link fullWidth underline href={links.home}>
                  {t('base.404.text2Link')}
                </Link>
                .
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
